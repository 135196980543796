import {
  createNewBannerPath,
  editBannerPath,
  getListBannerPath,
  removeBannerPath,
} from "../constants/apiRoutes";
import { bannerActions } from "../reducers/Admin/bannerActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewBanner = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewBannerPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/banner-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteBanner = (pageId) => {
  return (dispatch) => {
    dispatch(bannerActions.actionStart());
    return apiService
      .delete(removeBannerPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListBannerPath(0))
          .then((response) => {
            dispatch(bannerActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditBanner = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editBannerPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/banner-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchBannerPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(bannerActions.actionStart());
    return apiService
      .get(getListBannerPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(bannerActions.fetchAllPagesList(response.data));
        else dispatch(bannerActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
