import { configureStore } from "@reduxjs/toolkit";
import securityReducer from "./security";

import seoReducer from "../reducers/Admin/seoActions";
import qaReducer from "../reducers/Admin/qaActions";
import documentReducer from "../reducers/Admin/documentActions";
import clientReducer from "../reducers/Admin/clientActions";
import trafficReducer from "../reducers/Admin/trafficActions";
import vacationReducer from "../reducers/Admin/vacationActions";
import sliderReducer from "../reducers/Admin/sliderActions";
import bannerReducer from "../reducers/Admin/bannerActions";
import teamReducer from "../reducers/Admin/teamActions";
import productReducer from "../reducers/Admin/productActions";
import blogReducer from "../reducers/Admin/blogActions";
import caseStudyReducer from "../reducers/Admin/caseStudyActions";
import contactFormReducer from "../reducers/Admin/contactFormActions";
import roleReducer from "../reducers/Admin/roleActions";
import userReducer from "../reducers/Admin/userActions";
import resourcesReducer from "../reducers/Admin/resourcesActions";
import testimonialsReducer from "../reducers/Admin/testimonialsActions";
import newsletterReducer from "../reducers/Admin/newsletterActions";
import globalReducer from "../reducers/Site/globalActions";

const store = configureStore({
  reducer: {
    loggedUserReducer: securityReducer,
    seoReducer,
    qaReducer,
    documentReducer,
    clientReducer,
    blogReducer,
    caseStudyReducer,
    contactFormReducer,
    roleReducer,
    userReducer,
    testimonialsReducer,
    globalReducer,
    teamReducer,
    productReducer,
    resourcesReducer,
    newsletterReducer,
    sliderReducer,
    bannerReducer,
    trafficReducer,
    vacationReducer,
  },
});

export default store;
