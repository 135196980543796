import { TextField } from "@mui/material";
import { isUndefined } from "lodash";
import React from "react";

const InputField = (props) => {
  const {
    label,
    name,
    className,
    value,
    onChange,
    onKeyDown,
    required,
    readonly,
    disabled,
    multiline = false,
    rows,
    autoFocus,
    size,
    defaultValue,
    inputProps,
    inputLabelProps = {},
    error,
    type = "input",
  } = props || {};

  const inputValue = value === null ? "" : value;
  const errorMessage = typeof error !== "string" ? error?.[name] : error;
  const hasError = !isUndefined(errorMessage);

  return (
    <TextField
      label={label}
      name={name}
      defaultValue={defaultValue}
      rows={rows}
      multiline={multiline}
      error={hasError}
      readonly={readonly}
      className={`${className}`}
      helperText={errorMessage}
      value={inputValue}
      onChange={(event) => {
        onChange?.(event);
      }}
      onKeyDown={(event) => {
        onKeyDown?.(event);
      }}
      disabled={!!disabled}
      required={required}
      autoFocus={autoFocus}
      fullWidth
      size={size}
      type={type}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
    />
  );
};

export default InputField;
