import { newsletterActions } from "../reducers/Admin/newsletterActions.js";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";
import { getListNewsletterPath } from "../constants/apiRoutes";

export const fetchNewsletterPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(newsletterActions.actionStart());
    return apiService
      .get(getListNewsletterPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(newsletterActions.fetchAllPagesList(response.data));
        else dispatch(newsletterActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
