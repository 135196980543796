import React, { useRef, useState } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import AggridTable from "../components/Admin/Reusable/AggridTable";
import ButtonColumn from "../components/Admin/Reusable/ButtonColumn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchVacationPagesList,
  fetchDeleteVacation,
  fetchVacationPagesListSearch,
  fetchCreateNewVacation,
  fetchEditVacation,
} from "../actions/vacation";
import { isEmpty } from "lodash";
import { statusRender } from "../utils/cellRenderTable";
import { baseUrl, basicFormValidationHandler } from "../utils";
import InputField from "../components/Admin/Reusable/InputField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button,
  Tooltip,
  Alert,
  Autocomplete,
  TextField,
  Chip,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";

import SearchIcon from "@mui/icons-material/Search";
import Add from "@mui/icons-material/Add";
import { useModalToggle } from "../hooks/useModalToggle";
import CustomModal from "../components/Common/Modal/CustomModal";

import { NumericFormat } from "react-number-format";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Uvozimo dodatak za rad sa UTC vremenom
import timezone from "dayjs/plugin/timezone"; // Uvozimo dodatak za rad sa vremenskim zonama

import { differenceInDays, isWeekend, addDays } from "date-fns";
import { Info } from "@mui/icons-material";
import VacationCalendar from "./VacationCalendar";

// Aktiviramo uvezene dodatke
dayjs.extend(utc);
dayjs.extend(timezone);

// Postavljamo željenu vremensku zonu
dayjs.tz.setDefault("Europe/Belgrade");

const VacationList = () => {
  const dispatch = useDispatch();

  const { dataPagesList, dataPagesDetail } = useSelector(
    (state) => state.vacationReducer
  );
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const userData = loggedUserReducer?.loggedUser?.loggedUser;

  const [filteredRows, setFilteredRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [resetFilters, setResetFilters] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [preview, setPreview] = useState(false);
  const [approval, setApproval] = useState(false);
  const [viewCalendar, setViewCalendar] = useState(false);

  const handleFilterChange = (filteredData) => {
    // Ovde možete postaviti filtrirane redove u stanje roditeljske komponente
    setFilteredRows(filteredData);
  };

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteVacation(pageId));
  };

  const getVacationeDetail = (dataValue) => {
    setPreview(false);
    setApproval(true);
    dispatch(fetchVacationPagesList(dataValue.id));
  };

  const getVacationeDetailPreview = (dataValue) => {
    setPreview(true);
    dispatch(fetchVacationPagesList(dataValue.id));
  };

  const initialStateForm = {
    datum_od: null,
    datum_do: null,
    napomena: "",
    broj_dana: 0,
    fullName: "",
    status: "",
  };

  const [dataForm, setDataForm] = useState(initialStateForm);
  const [showModal, toggleModal] = useModalToggle(false);
  const [showModalRemove, toggleModalRemove] = useModalToggle(false);
  const [showModalCalendar, toggleModalCalendar] = useModalToggle(false);

  const today = dayjs(); // Trenutni datum

  useEffect(() => {
    dispatch(fetchVacationPagesList());
  }, [dispatch]);

  const filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      // pretvaranje datuma u milisekundama
      var cellDate = new Date(cellValue).setHours(0, 0, 0, 0);

      if (cellDate == null) {
        return -1;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
    browserDatePicker: true, // omogućavanje date picker-a u filteru
    clearButton: true, // omogućavanje dugmeta za brisanje filtera
  };

  const formatirajDatum = (datum) => {
    const dateObj = new Date(datum);

    const dan = dateObj.getDate().toString().padStart(2, "0");
    const mesec = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Meseci kreću od 0, pa se dodaje 1
    const godina = dateObj.getFullYear();

    return `${dan}.${mesec}.${godina} `;
  };

  const customCellRenderer = (params) => (
    <Fragment>
      {userData.role_id == 1 &&
        (params.data.status == 0 || params.data.status == 1) && (
          <Tooltip title="Potvrda zahteva" disableInteractive placement="top">
            <EditNoteIcon
              className="custom-btn-aggrid-customization edit-button-aggrid-delete-button"
              onClick={() => getVacationeDetail(params.data)}
            />
          </Tooltip>
        )}
      {params.data.status == 0 && (
        <Tooltip title="Brisanje" placement="top" disableInteractive>
          <DeleteIcon
            className="edit-button-aggrid-delete-button custom-btn-aggrid-customization"
            onClick={() => handleButtonRemoveClick(params.data)}
          />
        </Tooltip>
      )}
    </Fragment>
  );

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const handleButtonRemoveClick = (data) => {
    setSelectedRow(data);
    toggleModalRemove();
    setPreview(false);
  };

  const statusRender = (params) => {
    switch (params.data.status_name) {
      case "u obradi":
        return <Chip label="U obradi" color="warning" variant="outlined" />;
      case "prihvacen":
        return <Chip label="Odobren" color="success" variant="outlined" />;
      case "odbijen":
        return <Chip label="Odbijen" color="error" variant="outlined" />;
      default:
        break;
    }
  };

  //settings za tabelu
  const columnDefs = [
    // {
    //   headerName: "ID",
    //   field: "id",
    //   filter: "agTextColumnFilter",
    //   width: 100,
    // },
    {
      headerName: "Zaposleni",
      field: "fullName", // Polje koje će sadržavati spojeno ime i prezime
      filter: "agTextColumnFilter",
      width: 180,
      // pinned: "left",
      valueGetter: function (params) {
        // Spajanje imena i prezimena u jedan string
        return params.data.ins_firstname + " " + params.data.ins_lastname;
      },
    },
    {
      headerName: "Datum od",
      field: "datum_od",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatirajDatum(params.value),
      filterParams: filterParams,
      width: 180,
    },
    {
      headerName: "Datum do",
      field: "datum_do",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatirajDatum(params.value),
      filterParams: filterParams,
      width: 180,
    },
    {
      headerName: "Broj dana",
      field: "broj_dana",
      filter: "agTextColumnFilter",
      width: 120,
    },
    {
      headerName: "Status",
      field: "status_name",
      filter: "agTextColumnFilter",
      width: 200,
      cellRenderer: statusRender,
    },
    {
      headerName: "Datum zahteva",
      field: "datum_kreiranja",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatirajDatum(params.value),
      filterParams: filterParams,
      width: 200,
    },
    {
      headerName: "Napomena",
      field: "napomena",
      filter: "agTextColumnFilter",
      minWidth: 300,
      flex: 1,
    },
    {
      headerName: "",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: window.innerWidth < 786 ? "" : "right",
      width: 100,
    },
  ];

  const handleInputChangeForm = (event) => {
    const { name, value } = event.target;
    setDataForm((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const calculateWorkingDays = (startDate, endDate) => {
    let workingDays = 0;
    let currentDate = startDate;

    while (currentDate <= endDate) {
      if (!isWeekend(currentDate)) {
        workingDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1); // Dodajemo jedan dan
    }

    return workingDays;
  };

  const handleDateChangeForm = (date, field) => {
    setDataForm((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  useEffect(() => {
    if (dataForm.datum_od && dataForm.datum_do) {
      const startDate = new Date(dataForm.datum_od);
      const endDate = new Date(dataForm.datum_do);

      const workingDays = calculateWorkingDays(startDate, endDate);
      setDataForm((prevUser) => ({
        ...prevUser,
        ["broj_dana"]: workingDays,
      }));
      // Ovdje možete ažurirati stanje ili uraditi bilo šta drugo što je potrebno sa brojem radnih dana.
    }
  }, [dataForm.datum_od, dataForm.datum_do]);

  useEffect(() => {
    setFilteredRows(dataPagesList);
  }, [dataPagesList]);

  const clientValidationHandler = () => {
    const { datum_od, datum_do } = dataForm;
    const errorsObject = basicFormValidationHandler({
      datum_od,
      datum_do,
    });
    setErrors(errorsObject);
    return !isEmpty(errorsObject);
  };

  //akcija izmene
  const submitActionModal = () => {
    if (clientValidationHandler()) {
      return;
    }
    if (isEdit) {
      dataForm.id = dataPagesDetail.id;
      dispatch(fetchEditVacation(dataForm, modalCloseHandler));
    } else {
      dispatch(
        fetchCreateNewVacation(dataForm, resetDataForm, modalCloseHandler)
      ); // Čekamo da se akcija završi
    }
  };

  //akcija izmene
  const submitActionApprovalModal = () => {
    if (clientValidationHandler()) {
      return;
    }

    const newForm = {
      id: dataPagesDetail.id,
      status: 1,
      broj_dana: dataForm.broj_dana,
    };

    dispatch(fetchEditVacation(newForm, modalCloseHandler));
  };

  //akcija izmene
  const submitActionNotApprovalModal = () => {
    if (clientValidationHandler()) {
      return;
    }
    const newForm = {
      id: dataPagesDetail.id,
      status: 2,
      broj_dana: dataForm.broj_dana,
    };

    dispatch(fetchEditVacation(newForm, modalCloseHandler));
  };

  //akcija brisanja iz liste
  const submitActionModalRemoveItem = (id) => {
    dispatch(fetchDeleteVacation(id, closeModalFuncion));
  };

  const resetDataForm = () => {
    setDataForm(initialStateForm);
  };

  const closeModalFuncion = () => {
    toggleModalRemove(false);
  };

  const toggleModalFunction = () => {
    setDataForm(initialStateForm);
    setIsEdit(false);
    setPreview(false);
    setApproval(false);
    toggleModal();
  };

  const toggleModalCalendarFunction = () => {
    setIsEdit(false);
    setPreview(false);
    setApproval(false);
    toggleModalCalendar();
  };

  useEffect(() => {
    if (Object.keys(dataPagesDetail).length > 0) {
      setIsEdit(true);
      setDataForm({
        datum_od: dayjs(dataPagesDetail.datum_od),
        datum_do: dayjs(dataPagesDetail.datum_do),
        broj_dana: dataPagesDetail.broj_dana,
        napomena: dataPagesDetail.napomena,
        status: dataPagesDetail.status,
        fullName:
          dataPagesDetail.ins_firstname + " " + dataPagesDetail.ins_lastname,
      });
      toggleModal();
    }
  }, [dataPagesDetail]);

  const shouldDisableDate = (day) => {
    // Dobijanje dana u nedelji (0 - Nedelja, 6 - Subota)
    const dayOfWeek = dayjs(day).day();
    // Onemogućavanje vikenda (Subote i Nedelje)
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  console.log(userData, "userData");

  return (
    <Fragment>
      <Breadcrumb
        title="Pregled zahteva za godišnji odmor"
        type="vacation-lista"
      />
      <div className="row">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="filtered-inputs-form">
                <div className="filtered-inputs-form-content">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <form noValidate>
                      <div className="items-page-header-buttons">
                        <div className="page-item-button">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<Add />}
                            className="w-100 "
                            onClick={() => toggleModalFunction()}
                          >
                            Pošalji novi zahtev za odmor
                          </Button>
                        </div>
                        <div className="page-item-button">
                          <Button
                            variant="contained"
                            color="info"
                            startIcon={<Info />}
                            className="w-100 "
                            onClick={() => toggleModalCalendarFunction()}
                          >
                            Pogledaj kalendar
                          </Button>
                        </div>
                        <div className="page-item-button">
                          Preostalo dana odmora: <b>{userData.slobodni_dani}</b>
                        </div>
                      </div>
                    </form>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div
              className={`${
                userData["role_id"] == 1 ? "col-lg-12" : "col-lg-12"
              }`}
            >
              <AggridTable
                exportData={false}
                resetFilters={resetFilters}
                rowData={dataPagesList}
                columnDefs={columnDefs}
                onFilterChange={handleFilterChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showModalRemove && (
        <CustomModal
          show={showModalRemove}
          size={"lg"}
          hideModal={() => toggleModalRemove()}
          modalTitle={`Potvrda brisanja zahteva za godišnji odmor`}
          actionSubmitModal={() => submitActionModalRemoveItem(selectedRow.id)}
        >
          Da li ste sigurni da želite da obrišete zahtev za godišnji odmor ?
        </CustomModal>
      )}
      {showModal && (
        <CustomModal
          show={showModal}
          footerContent={approval == true ? false : true}
          footerButtonsApproval={approval}
          size={"lg"}
          hideModal={modalCloseHandler}
          modalTitle={
            preview
              ? `Pregled zahteva za godišnji odmor "` + dataForm.pnr + `"`
              : isEdit
              ? `Potvrda zahteva za godišnji odmor`
              : `Kreiranje zahteva za godišnji odmor`
          }
          actionSubmitModal={() => submitActionModal()}
          actionApprovalModal={() => submitActionApprovalModal()}
          actionNotApprovalModal={() => submitActionNotApprovalModal()}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form encType="multipart/form-data" noValidate>
              <div className="row">
                <div className="col-12">
                  {dataForm.status != "" && (
                    <Alert
                      severity={`${
                        dataForm.status == 0
                          ? "warning"
                          : dataForm.status == 1
                          ? "success"
                          : "error"
                      }`}
                      className="mb-3"
                    >
                      {dataForm.status == 0
                        ? "Zahtev je u obradi"
                        : dataForm.status == 1
                        ? "Zahtev je prihvaćen"
                        : "Zahtev je odbijen"}
                    </Alert>
                  )}
                  <div className="row">
                    <div className="col-lg-6">
                      <DatePicker
                        required
                        label="Datum početka odmora"
                        name="datum_od"
                        readOnly={approval}
                        value={dataForm.datum_od}
                        onChange={(date) =>
                          handleDateChangeForm(date, "datum_od")
                        }
                        fullWidth
                        slotProps={{ textField: { size: "small" } }}
                        inputSize="small"
                        minDate={today}
                        format="DD.MM.YYYY"
                        className="w-100 mb-4"
                        shouldDisableDate={shouldDisableDate}
                      />
                    </div>

                    <div className="col-lg-6">
                      <DatePicker
                        required
                        label="Datum završetka odmora"
                        name="datum_do"
                        readOnly={approval}
                        value={dataForm.datum_do}
                        onChange={(date) =>
                          handleDateChangeForm(date, "datum_do")
                        }
                        minDate={dataForm.datum_od}
                        fullWidth
                        slotProps={{ textField: { size: "small" } }}
                        inputSize="small"
                        format="DD.MM.YYYY"
                        className="w-100 mb-4"
                        shouldDisableDate={shouldDisableDate}
                      />
                    </div>
                    {dataForm.fullName != "" && (
                      <div className="col-lg-6 special-disabled-input">
                        <InputField
                          label="Ime i prezime zaposlenog"
                          autoFocus
                          value={dataForm.fullName}
                          required
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          size="small"
                          className="mb-4"
                        />
                      </div>
                    )}

                    <div className="col-lg-6 special-disabled-input">
                      <InputField
                        label="Ukupan broj dana odmora"
                        name="broj_dana"
                        autoFocus
                        value={dataForm.broj_dana}
                        error={errors}
                        onChange={handleInputChangeForm}
                        required
                        fullWidth
                        inputProps={{
                          readOnly: true,
                        }}
                        size="small"
                        className="mb-4"
                      />
                    </div>
                  </div>
                  <InputField
                    label="Napomena"
                    name="napomena"
                    value={dataForm.napomena}
                    onChange={handleInputChangeForm}
                    fullWidth
                    inputProps={{
                      readOnly: approval,
                    }}
                    rows={5}
                    multiline
                    size="small"
                    className="mb-4"
                  />
                </div>
              </div>
            </form>
          </LocalizationProvider>
        </CustomModal>
      )}
      {showModalCalendar && (
        <CustomModal
          show={showModalCalendar}
          size={"xxl"}
          hideModal={() => toggleModalCalendar()}
          modalTitle={"Kalendar"}
        >
          <VacationCalendar />
        </CustomModal>
      )}
    </Fragment>
  );
};

export default VacationList;
