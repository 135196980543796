import React, { useState } from "react";
import InputField from "./InputField";
import {
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import { FormControl } from "react-bootstrap";

const ProductMarginItem = ({ data, onChange }) => {
  const initialState = {
    marza: data.marza,
    gotovinsko: data.gotovinsko,
    status: data.status,
  };
  const [inputValue, setInputValue] = useState(initialState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleInputSelectChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
    onChange(value, data.naziv_kategorije, "status");
  };

  const handleKeyDownMarza = (e) => {
    if (e.key === "Enter") {
      onChange(inputValue.marza, data.naziv_kategorije, "marza");
    }
  };

  const handleKeyDownGotovinsko = (e) => {
    if (e.key === "Enter") {
      onChange(inputValue.gotovinsko, data.naziv_kategorije, "gotovinsko");
    }
  };

  return (
    <TableRow>
      <TableCell>{data.naziv_kategorije}</TableCell>
      <TableCell style={{ width: "200px" }}>
        <InputField
          required
          label="Marža"
          name="marza"
          onKeyDown={handleKeyDownMarza}
          value={inputValue.marza}
          onChange={handleInputChange}
          fullWidth
          size="small"
          className="mt-2 mb-2"
        />
      </TableCell>
      <TableCell style={{ width: "200px" }}>
        <InputField
          required
          label="Gotovinsko"
          name="gotovinsko"
          onKeyDown={handleKeyDownGotovinsko}
          value={inputValue.gotovinsko}
          onChange={handleInputChange}
          fullWidth
          size="small"
          className="mt-2 mb-2"
        />
      </TableCell>
      <TableCell style={{ width: "200px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={inputValue.status}
          label="Status"
          name="status"
          onChange={handleInputSelectChange}
          size="small"
          className="mt-2"
        >
          <MenuItem value="1">Aktivna</MenuItem>
          <MenuItem value="0">Neaktivna</MenuItem>
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default ProductMarginItem;
