import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import InputField from "../../components/Admin/Reusable/InputField";
import { basicFormValidationHandler } from "../../utils";
import { isEmpty } from "lodash";
import CustomModal from "../../components/Common/Modal/CustomModal";
import {
  createNewDocumentCategory,
  editDocumentCategoryFunction,
} from "../../actions/document";

const AddNewDocumentCategory = ({
  showModal,
  modalCloseHandlerCategory,
  dispatch,
  navigate,
  changedItemCategory,
}) => {
  const initialState = {
    anLanguage:
      changedItemCategory.length > 0 ? changedItemCategory[0].anLanguage : 1,
    acCategoryName: changedItemCategory.length
      ? changedItemCategory[0].acCategoryName
      : "",
  };

  const isEdit = changedItemCategory.length > 0 ? true : false;

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const clientValidationHandler = () => {
    const { acCategoryName } = data;
    const errorsObject = basicFormValidationHandler({
      acCategoryName,
    });
    setErrors(errorsObject);
    return !isEmpty(errorsObject);
  };

  const submitActionModal = () => {
    if (clientValidationHandler()) {
      return;
    }
    if (isEdit) {
      dispatch(
        editDocumentCategoryFunction(
          data,
          modalCloseHandlerCategory,
          navigate,
          changedItemCategory[0].anId
        )
      );
    } else {
      dispatch(
        createNewDocumentCategory(data, modalCloseHandlerCategory, navigate)
      );
    }
  };

  return (
    <CustomModal
      show={showModal}
      classValue={`modal-2`}
      hideModal={modalCloseHandlerCategory}
      modalTitle={
        isEdit
          ? `Izmena postojeće kategorije ${changedItemCategory[0].acCategoryName}`
          : `Kreiranje nove kategorije`
      }
      actionSubmitModal={() => submitActionModal()}
    >
      <form encType="multipart/form-data" noValidate>
        <div className="row">
          <div className="col-12">
            <FormControl fullWidth className="mb-4">
              <InputLabel id="demo-simple-select-label">Jezik</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.anLanguage}
                label="Jezik"
                name="anLanguage"
                onChange={handleInputChange}
                size="small"
              >
                <MenuItem value="1">Srpski</MenuItem>
                <MenuItem value="2">Engleski</MenuItem>
              </Select>
            </FormControl>
            <InputField
              label="Naziv kategorije"
              name="acCategoryName"
              value={data.acCategoryName}
              onChange={handleInputChange}
              error={errors}
              errorsHandler={setErrors}
              required
              fullWidth
              size="small"
              className="mb-4"
            />
          </div>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddNewDocumentCategory;
