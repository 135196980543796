import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSidebarMenu } from "../../../actions/security";
import NavItem from "./NavItem";
import CloseIcon from "@mui/icons-material/Close";

const LeftSidebar = ({ menuActive, setMenuAction }) => {
  const dispatch = useDispatch();

  const { setMenuItemsList, loggedUser } = useSelector(
    (state) => state.loggedUserReducer
  );

  const action = loggedUser?.loggedUser?.roles[0];
  useEffect(() => {
    dispatch(fetchSidebarMenu());
  }, [dispatch]);

  const userData = loggedUser?.loggedUser;

  const menuItemsList = setMenuItemsList?.filter((item) => {
    if (userData.role_id == 1) {
      return action.includes(item.acModule);
    } else if (userData.role_id == 14 && item.acModule == "MENU_USERS") {
      return false;
    }
    return action.includes(item.acModule);
  });

  return (
    <div className={`sidebar-main-content ${menuActive && "active"}`}>
      <div className="navbar-brand-box">
        <div href="#0" className="logo logo-light">
          <span className="logo-lg">
            <img
              src="/images/hipnos-logo.png"
              alt="Developers.rs"
              className="img-fluid"
            />
          </span>
        </div>
      </div>
      <div className="icon-close-mobile" onClick={() => setMenuAction(false)}>
        <CloseIcon />
      </div>
      <ul
        className="navbar-nav"
        id="navbar-nav"
        onClick={() => setMenuAction(false)}
      >
        {menuItemsList?.map((item, key) => (
          <NavItem item={item} key={key} />
        ))}
      </ul>
    </div>
  );
};

export default LeftSidebar;
