import {
  createNewSliderPath,
  editSliderPath,
  getListSliderPath,
  removeSliderPath,
} from "../constants/apiRoutes";
import { sliderActions } from "../reducers/Admin/sliderActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewSlider = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewSliderPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/slider-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteSlider = (pageId) => {
  return (dispatch) => {
    dispatch(sliderActions.actionStart());
    return apiService
      .delete(removeSliderPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListSliderPath(0))
          .then((response) => {
            dispatch(sliderActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditSlider = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editSliderPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/slider-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchSliderPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(sliderActions.actionStart());
    return apiService
      .get(getListSliderPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(sliderActions.fetchAllPagesList(response.data));
        else dispatch(sliderActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
