import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsletterPagesList } from "../actions/newsletter";
import AggridTable from "../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";

const NewsletterList = () => {
  const dispatch = useDispatch();

  const { dataPagesList } = useSelector((state) => state.newsletterReducer);

  useEffect(() => {
    dispatch(fetchNewsletterPagesList());
  }, [dispatch]);

  //settings za tabelu
  const columnDefs = [
    { headerName: "ID", field: "anId", filter: true, width: 100 },
    {
      headerName: "Email",
      field: "acEmail",
      filter: "agTextColumnFilter",
      flex: 1,
    },
    {
      headerName: "Datum",
      field: "adTimeIns",
      filter: "agDateColumnFilter",
      flex: 1,
    },
  ];
  return (
    <Fragment>
      <Breadcrumb title="Pregled newsletter prijava" type="newsletter-lista" />
      <AggridTable rowData={dataPagesList} columnDefs={columnDefs} />
    </Fragment>
  );
};

export default NewsletterList;
