import React from "react";

const Footer = () => {
  return (
    <div className="footer-main-content">
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2024 © Developers.rs</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Design &amp; Develop by Developers.rs
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
