import React, { useEffect } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { useSelector } from "react-redux";
import { fetchVacationCalendarPagesList } from "../actions/vacation";
import { useDispatch } from "react-redux";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Uvozimo dodatak za rad sa UTC vremenom
import timezone from "dayjs/plugin/timezone"; // Uvozimo dodatak za rad sa vremenskim zonama

// Aktiviramo uvezene dodatke
dayjs.extend(utc);
dayjs.extend(timezone);

// Postavljamo željenu vremensku zonu
dayjs.tz.setDefault("Europe/Belgrade");

const VacationCalendar = () => {
  const dispatch = useDispatch();

  const { dataPagesCalendarList, dataPagesDetail } = useSelector(
    (state) => state.vacationReducer
  );

  useEffect(() => {
    dispatch(fetchVacationCalendarPagesList());
  }, [dispatch]);

  const eventClassNames = (arg) => {
    const status = arg.event.extendedProps.status;

    // Provera statusa događaja
    if (status == 0) {
      // Ako je status 0, vraćamo klasu stila za narandžastu boju
      return "orange-event";
    } else if (arg.event.title == "Marko Savić") {
      return "red-event";
    } else if (arg.event.title == "Dragana Subotić") {
      return "yellow-event";
    } else if (arg.event.title == "Violeta Paunović") {
      return "blue-event";
    } else if (arg.event.title == "Tamara Vujosević") {
      return "blue2-event";
    } else if (arg.event.title == "Ivona Mitić") {
      return "green-event";
    } else if (arg.event.title == "Tamara Atlagić") {
      return "purple-event";
    } else if (arg.event.title == "Milan Savić") {
      return "grey-event";
    } else if (arg.event.title == "Mladenko Radonjić") {
      return "yellow-event";
    } else {
      return "green2-event";
    }
  };

  const handleEventClick = (arg) => {
    const status = arg.event.extendedProps.status;
    const id = arg.event.extendedProps.eventId;
  };

  const events = dataPagesCalendarList
    ?.filter((event) => event.status == 0 || event.status == 1)
    ?.map((event) => ({
      title: `${event.ins_firstname} ${event.ins_lastname}`,
      start: dayjs(event.datum_od).startOf("day").format("YYYY-MM-DD"),
      end: dayjs(event.datum_do)
        .startOf("day")
        .add(1, "day")
        .format("YYYY-MM-DD"),
      status: event.status,
      eventId: event.id,
    }));

  return (
    <div className="card card-item-view-calendar">
      <div className="card-body">
        <FullCalendar
          plugins={[dayGridPlugin, multiMonthPlugin]}
          initialView={"multiMonthYear"}
          events={events}
          eventClassNames={eventClassNames}
          multiMonthMaxColumns={2}
          eventClick={handleEventClick}
        />
      </div>
    </div>
  );
};

export default VacationCalendar;
