import { Logout, Person, PersonAdd, Settings } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { logout } from "../../actions/security";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MenuAccountSettings = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFunction = () => {
    dispatch(logout(navigate));
  };

  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const userData = loggedUserReducer?.loggedUser?.loggedUser;
  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <div
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            className="account-settings-item"
          >
            <Avatar sx={{ width: 35, height: 35, fontSize: 15 }}>NM</Avatar>
            <div className="account-settings-list-items">
              <span className="account-settings-fullname">
                {userData.first_name} {userData.last_name}
              </span>
              <span className="account-settings-email">{userData.email}</span>
            </div>
          </div>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem component={Link} to="/adminManager/user-add">
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Kreiraj novog korisnika
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem>
          <Link to={`/adminManager/user-edit/${userData.user_id}`}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            Moj profil
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logoutFunction}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Odjavi se
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default MenuAccountSettings;
