import {
  createNewTrafficPath,
  editTrafficPath,
  getListTrafficPath,
  getListTrafficPathSearch,
  removeTrafficPath,
} from "../constants/apiRoutes";
import { trafficActions } from "../reducers/Admin/trafficActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewTraffic = (dataValues, resetDataForm, data) => {
  return (dispatch) => {
    return apiService
      .post(createNewTrafficPath(), dataValues)
      .then((response) => {
        resetDataForm();
        toast.success(response.data.message, { theme: "colored" });

        return apiService
          .post(getListTrafficPathSearch(), data)
          .then((response) => {
            if (response.data.length > 0) {
              dispatch(trafficActions.fetchAllPagesList(response.data));
            } else {
              toast.info("Za odabrani opseg datuma nema unetih podataka.", {
                theme: "colored",
              });
            }
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteTraffic = (pageId, closeModalFuncion, data) => {
  return (dispatch) => {
    dispatch(trafficActions.actionStart());
    return apiService
      .delete(removeTrafficPath(pageId))
      .then((response) => {
        closeModalFuncion();
        toast.success(response.data.message, { theme: "colored" });

        return apiService
          .post(getListTrafficPathSearch(), data)
          .then((response) => {
            if (response.data.length > 0) {
              dispatch(trafficActions.fetchAllPagesList(response.data));
            } else {
              toast.info("Za odabrani opseg datuma nema unetih podataka.", {
                theme: "colored",
              });
            }
          });
        // return apiService
        //   .get(getListTrafficPath(0))
        //   .then((response) => {
        //     dispatch(trafficActions.fetchAllPagesList(response.data));
        //   })
        //   .catch((err) => {
        //     let errorMessage = err.response ? err.response.data.message : err;
        //     if (!err.response) {
        //       toast.error(
        //         "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
        //         { theme: "colored" }
        //       );
        //     }
        //     toast.error(errorMessage, { theme: "colored" });
        //   });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditTraffic = (dataValues, modalCloseHandler, data) => {
  return (dispatch) => {
    return apiService
      .post(editTrafficPath(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });

        modalCloseHandler();
        return apiService
          .post(getListTrafficPathSearch(), data)
          .then((response) => {
            if (response.data.length > 0) {
              dispatch(trafficActions.fetchAllPagesList(response.data));
            } else {
              toast.info("Za odabrani opseg datuma nema unetih podataka.", {
                theme: "colored",
              });
            }
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchTrafficPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(trafficActions.actionStart());
    return apiService
      .get(getListTrafficPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(trafficActions.fetchAllPagesList(response.data));
        else dispatch(trafficActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchTrafficPagesListSearch = (data) => {
  return (dispatch) => {
    dispatch(trafficActions.actionStart());
    return apiService
      .post(getListTrafficPathSearch(), data)
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(trafficActions.fetchAllPagesList(response.data));
        } else {
          toast.info("Za odabrani opseg datuma nema unetih podataka.", {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchTrafficPagesListSearch2 = (data) => {
  return (dispatch) => {
    dispatch(trafficActions.actionStart());
    return apiService
      .post(getListTrafficPathSearch(), data)
      .then((response) => {
        dispatch(trafficActions.fetchAllPagesList(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
