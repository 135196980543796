import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import * as XLSX from "xlsx";

//css
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../assets/Admin/table-data.scss";

import agGridTranslations from "../../../agGridTranslations.json";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  fetchLoadProducts,
  fetchLoadProductsCheck,
} from "../../../actions/product";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const AggridTable = (props) => {
  const {
    rowData,
    columnDefs,
    paginationPageSize = 100,
    onFilterChange,
    resetFilters,
    exportData = true,
  } = props || {};

  const gridRef = useRef();
  const dispatch = useDispatch();

  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const userData = loggedUserReducer?.loggedUser?.loggedUser;

  const defaultColDef = {
    sortable: true,
    resizable: true,
    pagination: true,
    floatingFilter: true,
  };

  useEffect(() => {
    if (rowData && gridRef.current.api) {
      // Resetiranje filtera u Ag Grid-u
      gridRef.current.api.setFilterModel(null);
    }
  }, [rowData]);

  useEffect(() => {
    const eventListener = () => {
      const filteredRowCount = gridRef.current.api.getModel().getRowCount();
      // Dobijanje filtriranih redova iz ag-Grid-a
      const filteredRows = gridRef.current.api
        .getModel()
        .rowsToDisplay.map((row) => row.data);
      // Poziv callback funkcije roditeljske komponente sa filtriranim podacima
      onFilterChange(filteredRows);
    };

    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.addEventListener("filterChanged", eventListener);
    }

    return () => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.removeEventListener("filterChanged", eventListener);
      }
    };
  }, [onFilterChange]);

  const exportToExcel = () => {
    // Dobijanje podataka iz tabele
    const rowData = gridRef.current.api
      .getModel()
      .rowsToDisplay.map((row) => row.data);

    // Definisanje kolona
    const header = Object.keys(rowData[0]);
    const data = rowData.map((row) =>
      header.map((fieldName) => row[fieldName])
    );

    // Kreiranje radnog lista
    const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Kreiranje binarnih podataka Excel fajla
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Konverzija binarnih podataka u Blob
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Kreiranje linka za preuzimanje
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Pomoćna funkcija za konverziju stringa u binarne podatke
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <>
      {exportData && (
        <div className="button-export-to-excel">
          {userData["role_id"] == 1 && (
            <Button
              onClick={exportToExcel}
              type="submit"
              variant="contained"
              color="info"
              size="small"
              startIcon={<DownloadIcon />}
            >
              Izvezi podatke u excel
            </Button>
          )}
        </div>
      )}
      <div className="table-grid-compontent-main ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          paginationPageSize={paginationPageSize}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          animateRows={true}
          localeText={agGridTranslations}
          pagination={true}
        />
      </div>
    </>
  );
};

export default AggridTable;
