import {
  createNewVacationPath,
  editVacationPath,
  getListVacationPath,
  getListVacationPathSearch,
  removeVacationPath,
  getListVacationCalendarPath,
} from "../constants/apiRoutes";
import { vacationActions } from "../reducers/Admin/vacationActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewVacation = (
  dataValues,
  resetDataForm,
  modalCloseHandler
) => {
  return (dispatch) => {
    return apiService
      .post(createNewVacationPath(), dataValues)
      .then((response) => {
        resetDataForm();
        modalCloseHandler();
        toast.success(response.data.message, { theme: "colored" });

        return apiService
          .get(getListVacationPath(0))
          .then((response) => {
            dispatch(vacationActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteVacation = (pageId, closeModalFuncion, data) => {
  return (dispatch) => {
    dispatch(vacationActions.actionStart());
    return apiService
      .delete(removeVacationPath(pageId))
      .then((response) => {
        closeModalFuncion();
        toast.success(response.data.message, { theme: "colored" });

        return apiService
          .get(getListVacationPath(0))
          .then((response) => {
            dispatch(vacationActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
        // return apiService
        //   .get(getListVacationPath(0))
        //   .then((response) => {
        //     dispatch(vacationActions.fetchAllPagesList(response.data));
        //   })
        //   .catch((err) => {
        //     let errorMessage = err.response ? err.response.data.message : err;
        //     if (!err.response) {
        //       toast.error(
        //         "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
        //         { theme: "colored" }
        //       );
        //     }
        //     toast.error(errorMessage, { theme: "colored" });
        //   });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditVacation = (dataValues, modalCloseHandler, data) => {
  return (dispatch) => {
    return apiService
      .post(editVacationPath(), dataValues)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });

        modalCloseHandler();

        return apiService
          .get(getListVacationPath(0))
          .then((response) => {
            dispatch(vacationActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchVacationPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(vacationActions.actionStart());
    return apiService
      .get(getListVacationPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(vacationActions.fetchAllPagesList(response.data));
        else dispatch(vacationActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchVacationCalendarPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(vacationActions.actionStart());
    return apiService
      .get(getListVacationCalendarPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(vacationActions.fetchAllCalendarPagesList(response.data));
        else dispatch(vacationActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchVacationPagesListSearch = (data) => {
  return (dispatch) => {
    dispatch(vacationActions.actionStart());
    return apiService
      .post(getListVacationPathSearch(), data)
      .then((response) => {
        if (response.data.length > 0) {
          dispatch(vacationActions.fetchAllPagesList(response.data));
        } else {
          toast.info("Za odabrani opseg datuma nema unetih podataka.", {
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
