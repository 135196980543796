import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useParams } from "react-router-dom";

import { fetchCategoryGroupList } from "../actions/product";
import { useDispatch, useSelector } from "react-redux";
import ProductMarginTable from "../components/Admin/Reusable/ProductMarginTable";
import LoadingIndicator from "../components/Admin/Reusable/LoadingIndicator";

const ProductMargin = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { dataCategoryGroup, loading } = useSelector(
    (state) => state.productReducer
  );

  useEffect(() => {
    dispatch(fetchCategoryGroupList());
  }, [id, dispatch]);

  return (
    <Fragment>
      <Breadcrumb title="Grupa proizvoda" type="product-forma" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h1 className="card-title mb-0 flex-grow-1">
                  {/* Kreiranje novog pitanja */}
                  {id
                    ? `Izmena grupe marze proizvoda`
                    : "Izmena grupe marze proizvoda"}
                </h1>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    {!!dataCategoryGroup && (
                      <ProductMarginTable data={dataCategoryGroup} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingIndicator loading={loading} />
    </Fragment>
  );
};

export default ProductMargin;
