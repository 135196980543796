import {
  createNewTeamPath,
  editTeamPath,
  getListTeamPath,
  removeTeamPath,
} from "../constants/apiRoutes";
import { teamActions } from "../reducers/Admin/teamActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewTeam = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewTeamPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/team-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteTeam = (pageId) => {
  return (dispatch) => {
    dispatch(teamActions.actionStart());
    return apiService
      .delete(removeTeamPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListTeamPath(0))
          .then((response) => {
            dispatch(teamActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditTeam = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editTeamPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/team-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchTeamPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(teamActions.actionStart());
    return apiService
      .get(getListTeamPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(teamActions.fetchAllPagesList(response.data));
        else dispatch(teamActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
