import {
  createNewCaseStudyPath,
  editCaseStudyPath,
  getListCaseStudyPath,
  removeCaseStudyPath,
  getReadFileCaseStudyPath,
} from "../constants/apiRoutes";
import { caseStudyActions } from "../reducers/Admin/caseStudyActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewCaseStudy = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewCaseStudyPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/case-study-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteCaseStudy = (pageId) => {
  return (dispatch) => {
    dispatch(caseStudyActions.actionStart());
    return apiService
      .delete(removeCaseStudyPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListCaseStudyPath(0))
          .then((response) => {
            dispatch(caseStudyActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditCaseStudy = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editCaseStudyPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/case-study-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchCaseStudyPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(caseStudyActions.actionStart());
    return apiService
      .get(getListCaseStudyPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(caseStudyActions.fetchAllPagesList(response.data));
        else dispatch(caseStudyActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const getFileReadFromCaseStudy = (document) => {
  return (dispatch) => {
    dispatch(caseStudyActions.actionStart());
    return apiService
      .getFromFile(getReadFileCaseStudyPath(document))
      .then((response) => {
        // Pravimo Blob objekat od podataka koje smo dobili
        const blob = new Blob([response.data], { type: "application/pdf" });

        // Kreiramo URL za Blob
        const url = URL.createObjectURL(blob);

        // Otvaranje URL-a u novom tabu
        window.open(url, "_blank");
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
