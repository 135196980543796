import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../components/Admin/Reusable/InputField";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { isEmpty, isUndefined } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { baseUrl, basicFormValidationHandler } from "../utils";
import {
  fetchTeamPagesList,
  fetchCreateNewTeam,
  fetchEditTeam,
} from "../actions/team";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "ckeditor5-custom-build/build/ckeditor";

const Team = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.teamReducer);

  const resetState = useCallback(() => {
    return {
      acName: "",
      acPosition: "",
      acDescription: "",
      anStatus: 1,
      anLanguage: 1,
      acImage: "",
    };
  }, []);

  const initialState = {
    acName: "",
    acPosition: "",
    acDescription: "",
    anStatus: 1,
    anLanguage: 1,
    acImage: "",
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1048576; // Konvertujemo veličinu u MB
      if (fileSizeInMB <= 5) {
        setSelectedFile(file);
      } else {
        toast.error(
          "Odabrana slika je prevelika. Maksimalna veličina je 3MB.",
          { theme: "colored" }
        );
        setData((prevUser) => ({
          ...prevUser,
          acDocument: null,
        }));
        event.target.value = null; // Resetujemo input
      }
    }
  };
  useEffect(() => {
    if (id) {
      dispatch(fetchTeamPagesList(id));
    } else {
      setData(resetState());
    }
  }, [id, dispatch, resetState]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail);
    }
  }, [dataPagesDetail]);

  const teamValidationHandler = () => {
    const { acName, acPosition, acDescription } = data;

    let errorsObject = {};
    if (id) {
      errorsObject = basicFormValidationHandler({
        acName,
        acPosition,
        acDescription,
      });
    } else {
      errorsObject = basicFormValidationHandler({
        acName,
        acPosition,
        acDescription,
        selectedFile,
      });
    }

    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (teamValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acName", data.acName);
    formData.append("acPosition", data.acPosition);
    formData.append("anLanguage", data.anLanguage);
    formData.append("acDescription", data.acDescription);
    formData.append("anStatus", data.anStatus);
    selectedFile && formData.append("acImage", selectedFile);

    if (id) {
      formData.append("anId", id);
      dispatch(fetchEditTeam(formData, navigate));
    } else {
      dispatch(fetchCreateNewTeam(formData, navigate));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Tim" type="team-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {/* Kreiranje novog pitanja */}
                {id ? `Izmena člana tima` : "Kreiranje novog člana"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <FormControl fullWidth className="mb-4">
                      <InputLabel id="demo-simple-select-label">
                        Jezik
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anLanguage}
                        label="Jezik"
                        name="anLanguage"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Srpski</MenuItem>
                        <MenuItem value="2">Engleski</MenuItem>
                      </Select>
                    </FormControl>
                    <InputField
                      required
                      label="Ime i prezime"
                      name="acName"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acName}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputField
                      required
                      label="Pozicija"
                      name="acPosition"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acPosition}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />
                    <InputLabel id="demo-simple-select-label" className="mb-2">
                      Opis:
                    </InputLabel>
                    <CKEditor
                      name="acDescription"
                      editor={Editor}
                      data={data.acDescription}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setData((prevUser) => ({
                          ...prevUser,
                          acDescription: data,
                        }));
                      }}
                    />
                    <div className="description-error-text">
                      {errors["acDescription"] && (
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          opis je obavezan!
                        </p>
                      )}
                    </div>
                    <FormControl fullWidth className="mt-4">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.anStatus}
                        label="Status"
                        name="anStatus"
                        onChange={handleInputChange}
                        size="small"
                      >
                        <MenuItem value="1">Aktivan</MenuItem>
                        <MenuItem value="0">Neaktivan</MenuItem>
                      </Select>
                    </FormControl>
                    {id && (
                      <div className="inseted-document-link">
                        Slika:&nbsp;
                        <div>
                          <img
                            src={baseUrl + "images/" + dataPagesDetail.acImage}
                            className="img-fluid image-preview-block-table-detail"
                            alt="HIPNOS"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-6">
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginBottom: "10px" }}
                    >
                      Slika <br />
                      <small>
                        <i>
                          (maksimalna veličina slike do <b>5MB</b>)
                        </i>
                      </small>
                      <br />
                      <small>
                        <i>
                          (dozvoljeni formati <b>.jpg, .jpeg, .png, .svg</b>)
                        </i>
                      </small>
                    </InputLabel>
                    <Input
                      required
                      type="file"
                      error={!isUndefined(errors["selectedFile"])}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files.length === 0) {
                          setSelectedFile(null);
                        } else {
                          handleFileSelect(e);
                        }
                      }}
                      id="file-input"
                      name="acFileName"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      style={{ position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Priložite sliku
                      </Button>
                      {!isUndefined(errors["selectedFile"]) && (
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          prilog je obavezan!
                        </p>
                      )}
                    </label>
                    {selectedFile && (
                      <div>
                        <br />
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                Odabrana slika: <b>{selectedFile.name}</b>{" "}
                                (Veličina:{" "}
                                {(selectedFile.size / 1048576).toFixed(2)} MB)
                                {selectedFile && (
                                  <div className="preview-image-holder-admin">
                                    <img
                                      src={URL.createObjectURL(selectedFile)}
                                      alt={selectedFile.name}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="contained"
                                  color="error"
                                  type="button"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    setSelectedFile(null);
                                    document.getElementById(
                                      "file-input"
                                    ).value = ""; // Resetovanje input polja za datoteku
                                  }}
                                >
                                  Obriši
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p></p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Team;
