import {
  createNewResourcesPath,
  editResourcesPath,
  getListResourcesPath,
  removeResourcesPath,
} from "../constants/apiRoutes";
import { resourcesActions } from "../reducers/Admin/resourcesActions";
import apiService from "../utils/configAxios";
import { toast } from "react-toastify";

export const fetchCreateNewResources = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(createNewResourcesPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/resources-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchDeleteResources = (pageId) => {
  return (dispatch) => {
    dispatch(resourcesActions.actionStart());
    return apiService
      .delete(removeResourcesPath(pageId))
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        return apiService
          .get(getListResourcesPath(0))
          .then((response) => {
            dispatch(resourcesActions.fetchAllPagesList(response.data));
          })
          .catch((err) => {
            let errorMessage = err.response ? err.response.data.message : err;
            if (!err.response) {
              toast.error(
                "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
                { theme: "colored" }
              );
            }
            toast.error(errorMessage, { theme: "colored" });
          });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchEditResources = (dataValues, navigate) => {
  return (dispatch) => {
    return apiService
      .post(editResourcesPath(), dataValues)
      .then((response) => {
        navigate("/adminManager/resources-list");
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};

export const fetchResourcesPagesList = (pageId = 0) => {
  return (dispatch) => {
    dispatch(resourcesActions.actionStart());
    return apiService
      .get(getListResourcesPath(pageId))
      .then((response) => {
        if (pageId === 0)
          dispatch(resourcesActions.fetchAllPagesList(response.data));
        else dispatch(resourcesActions.fetchAllPagesDetail(response.data));
      })
      .catch((err) => {
        let errorMessage = err.response ? err.response.data.message : err;
        if (!err.response) {
          toast.error(
            "Greška, pokušajte da osvežite stranu pa ponovo pokušajte istu akciju.",
            { theme: "colored" }
          );
        }
        toast.error(errorMessage, { theme: "colored" });
      });
  };
};
