import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../components/Admin/Reusable/InputField";
import { baseUrl, basicFormValidationHandler } from "../utils";
import { isEmpty, isUndefined } from "lodash";
import { toast } from "react-toastify";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import {
  fetchBlogPagesList,
  fetchCreateNewBlog,
  fetchEditBlog,
} from "../actions/blog";
import { useCallback } from "react";

const Blog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.blogReducer);
  const resetState = useCallback(() => {
    return {
      acTitle: "",
      acImage: "",
      isPrimary: false,
    };
  }, []);

  const initialState = {
    acTitle: "",
    acImage: "",
    isPrimary: false,
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState("");

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / 1048576; // Konvertujemo veličinu u MB
      if (fileSizeInMB <= 5) {
        setSelectedFile(file);
      } else {
        toast.error(
          "Odabrana slika je prevelika. Maksimalna veličina je 5MB.",
          { theme: "colored" }
        );
        setData((prevUser) => ({
          ...prevUser,
          acDocument: null,
        }));
        event.target.value = null; // Resetujemo input
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchBlogPagesList(id));
    } else {
      setData(resetState());
    }
  }, [id, dispatch, resetState]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail[0]);
    }
  }, [dataPagesDetail]);

  const clientValidationHandler = () => {
    const { acTitle } = data;
    let errorsObject = {};
    if (id) {
      errorsObject = basicFormValidationHandler({
        acTitle,
      });
    } else {
      errorsObject = basicFormValidationHandler({
        acTitle,
        selectedFile,
      });
    }
    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: name === "isPrimary" ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (clientValidationHandler()) {
      return;
    }

    const formData = new FormData();

    formData.append("acTitle", data.acTitle);
    formData.append("isPrimary", data.isPrimary);
    selectedFile && formData.append("acImage", selectedFile);

    if (id) {
      formData.append("anId", id);
      dispatch(fetchEditBlog(formData, navigate));
    } else {
      dispatch(fetchCreateNewBlog(formData, navigate));
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Blog" type="blog-forma" />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h1 className="card-title mb-0 flex-grow-1">
                {id ? `Izmena bloga` : "Kreiranje novog bloga"}
              </h1>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-6">
                    <InputField
                      required
                      label="Naslov"
                      name="acTitle"
                      error={errors}
                      errorsHandler={setErrors}
                      value={data.acTitle}
                      onChange={handleInputChange}
                      fullWidth
                      size="small"
                      className="mb-4"
                    />

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isPrimary"
                            checked={data.isPrimary}
                            onChange={handleInputChange}
                          />
                        }
                        label="Istaknuta vest"
                      />
                    </FormGroup>

                    {id && (
                      <div className="inseted-document-link">
                        Slika:&nbsp;
                        <div>
                          <img
                            src={baseUrl + "images/" + data.acImage}
                            className="img-fluid image-preview-block-table-detail"
                            alt="HIPNOS"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-6">
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginBottom: "10px" }}
                    >
                      Slika <br />
                      <small>
                        <i>
                          (maksimalna veličina slike do <b>5MB</b>)
                        </i>
                      </small>
                      <br />
                      <small>
                        <i>
                          (dozvoljeni formati <b>.jpg, .jpeg, .png, .svg</b>)
                        </i>
                      </small>
                    </InputLabel>
                    <Input
                      required
                      type="file"
                      error={!isUndefined(errors["selectedFile"])}
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files.length === 0) {
                          setSelectedFile(null);
                        } else {
                          handleFileSelect(e);
                        }
                      }}
                      id="file-input"
                      name="acFileName"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="file-input"
                      style={{ position: "relative" }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                      >
                        Priložite sliku
                      </Button>
                      {!isUndefined(errors["selectedFile"]) && (
                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          prilog je obavezan!
                        </p>
                      )}
                    </label>
                    {selectedFile && (
                      <div>
                        <br />
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                Odabrana slika: <b>{selectedFile.name}</b>{" "}
                                (Veličina:{" "}
                                {(selectedFile.size / 1048576).toFixed(2)} MB)
                                {selectedFile && (
                                  <div className="preview-image-holder-admin">
                                    <img
                                      src={URL.createObjectURL(selectedFile)}
                                      alt={selectedFile.name}
                                    />
                                  </div>
                                )}
                              </td>
                              <td>
                                <Button
                                  variant="contained"
                                  color="error"
                                  type="button"
                                  size="small"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    setSelectedFile(null);
                                    document.getElementById(
                                      "file-input"
                                    ).value = ""; // Resetovanje input polja za datoteku
                                  }}
                                >
                                  Obriši
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p></p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="float-end mt-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Blog;
