import React, { useEffect } from "react";
import { Fragment } from "react";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import InputField from "../components/Admin/Reusable/InputField";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { isEmpty } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { basicFormValidationHandler } from "../utils";
import {
  fetchProductPagesList,
  fetchCreateNewProduct,
  fetchEditProduct,
} from "../actions/product";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

const Product = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dataPagesDetail } = useSelector((state) => state.productReducer);

  const resetState = useCallback(() => {
    return {
      b2bcena: "",
      barcod: "",
      cena: "",
      cena_gotovinsko: "",
      cena_sa_popustom: "",
      datum_izmene: "",
      datum_unosa: "",
      deklaracija: "",
      energetska_klasa: "",
      energetska_klasa_link: "",
      energetska_klasa_pdf: "",
      flag_akcija_cena: "",
      grupa: "",
      id: "",
      izmenio: "",
      jedinica_mere: "",
      kolicina: "",
      model: "",
      mpcena: "",
      nadgrupa: "",
      naziv: "",
      opis: "",
      pdv: "",
      proizvodjac: "",
      sifra: "",
      slika: "",
      uneo: "",
      valuta: "",
      web_cena: "",
      status: "",
      akcija: "",
      izdvojeno: "",
      popularno: "",
      ponovo_na_stanju: "",
      preporucujemo: "",
      mesecna_akcija: "",
      cena_bez_pdv: "",
    };
  }, []);

  const initialState = {
    b2bcena: "",
    barcod: "",
    cena: "",
    cena_gotovinsko: "",
    cena_sa_popustom: "",
    datum_izmene: "",
    datum_unosa: "",
    deklaracija: "",
    energetska_klasa: "",
    energetska_klasa_link: "",
    energetska_klasa_pdf: "",
    flag_akcija_cena: "",
    grupa: "",
    id: "",
    izmenio: "",
    jedinica_mere: "",
    kolicina: "",
    model: "",
    mpcena: "",
    nadgrupa: "",
    naziv: "",
    opis: "",
    pdv: "",
    proizvodjac: "",
    sifra: "",
    slika: "",
    uneo: "",
    valuta: "",
    web_cena: "",
    status: "",
    akcija: "",
    izdvojeno: "",
    popularno: "",
    ponovo_na_stanju: "",
    preporucujemo: "",
    mesecna_akcija: "",
    cena_bez_pdv: "",
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(fetchProductPagesList(id));
    } else {
      setData(resetState());
    }
  }, [id, dispatch, resetState]);

  useEffect(() => {
    if (dataPagesDetail && Object.keys(dataPagesDetail).length > 0) {
      setData(dataPagesDetail[0]);
    }
  }, [dataPagesDetail]);

  const productValidationHandler = () => {
    const { cena_sa_popustom } = data;

    let errorsObject = {};
    errorsObject = basicFormValidationHandler({
      cena_sa_popustom,
    });

    setErrors(errorsObject);
    if (!isEmpty(errorsObject))
      toast.error("Morate popuniti sva obavezna polja", { theme: "colored" });
    return !isEmpty(errorsObject);
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;

    // Ako je ček-boks, postavite vrednost na "1" ako je čekiran, inače "0"
    const inputValue = type === "checkbox" ? (checked ? "1" : "0") : value;

    setData((prevUser) => ({
      ...prevUser,
      [name]: inputValue,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Postavljanje podrazumevanih vrednosti ako su NULL
    const status = data.status !== null ? data.status : 1; // Postaviti status na 1 ako je null
    const akcija = data.akcija !== null ? data.akcija : 0; // Postaviti akciju na 0 ako je null
    const izdvojeno = data.izdvojeno !== null ? data.izdvojeno : 0; // Postaviti izdvojeno na 0 ako je null
    const popularno = data.popularno !== null ? data.popularno : 0; // Postaviti popularno na 0 ako je null
    const ponovo_na_stanju =
      data.ponovo_na_stanju !== null ? data.ponovo_na_stanju : 0; // Postaviti popularno na 0 ako je null
    const preporucujemo = data.preporucujemo !== null ? data.preporucujemo : 0; // Postaviti popularno na 0 ako je null
    const cenaFinal =
      data.cena_sa_popustom !== null ? data.cena_sa_popustom : ""; // Postaviti popularno na 0 ako je null
    const mesecna_akcija =
      data.mesecna_akcija !== null ? data.mesecna_akcija : 0; // Postaviti nedeljnu akciju na 0 ako je null

    // Kreiranje FormData objekta sa svim potrebnim podacima
    const formData = new FormData();
    formData.append("cena", cenaFinal);
    formData.append("sifra", data.sifra);
    formData.append("grupa", data.grupa);
    formData.append("status", status);
    formData.append("akcija", akcija);
    formData.append("popularno", popularno);
    formData.append("mesecna_akcija", mesecna_akcija);
    formData.append("izdvojeno", izdvojeno);
    formData.append("ponovo_na_stanju", ponovo_na_stanju);
    formData.append("preporucujemo", preporucujemo);

    // Prosleđivanje formData objekta zahtevu za kreiranje ili izmenu proizvoda
    if (id) {
      dispatch(fetchEditProduct(formData, id));
    } else {
      dispatch(fetchCreateNewProduct(formData));
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        title={`${data.sifra} - ${data.naziv}`}
        type="product-forma"
      />
      <div className="row">
        <div className="col-12">
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col">
                <div className="float-end mb-3">
                  {id ? (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<CheckIcon />}
                    >
                      Potvrda izmene
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      startIcon={<AddIcon />}
                    >
                      Kreiraj
                    </Button>
                  )}
                </div>
              </div>
              <div className="product-admin-holder-item">
                <div className="product-holder-admin-left-side">
                  <div className="card">
                    <div className="card-body">
                      <div className="holder-left-side-main">
                        <div className="product-holder-admin-left-side-image">
                          <img src={data.slika} className="img-fluid" />
                        </div>
                        <div className="product-holder-admin-left-side-text">
                          <h6 class="fs-15 mb-3">Osnovni podaci</h6>
                          <div className="row">
                            <div className="col-12">
                              <InputField
                                label="sifra"
                                name="sifra"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.sifra}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="barcod"
                                name="barcod"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.barcod}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="naziv"
                                name="naziv"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.naziv}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="proizvodjac"
                                name="proizvodjac"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.proizvodjac}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="nadgrupa"
                                name="nadgrupa"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.nadgrupa}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="grupa"
                                name="grupa"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.grupa}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                            <div className="col-12">
                              <InputField
                                label="model"
                                name="model"
                                error={errors}
                                errorsHandler={setErrors}
                                value={data.model}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                className="mb-4"
                                inputProps={{
                                  readOnly: true,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-holder-admin-right-side">
                  <div className="card">
                    <div className="card-header">Stanje proizvoda</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="Cena bez pdv"
                            name="cena_bez_pdv"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.cena_bez_pdv}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="Cena sa pdv"
                            name="cena"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.cena}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="Nova Cena sa pdv"
                            name="cena_sa_popustom"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.cena_sa_popustom}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="Cena za gotovisnko plaćanje sa pdv"
                            name="cena_gotovinsko"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.cena_gotovinsko}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6">
                          <InputField
                            label="Količina"
                            name="kolicina"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.kolicina}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6">
                          <InputField
                            label="Jedinica mere"
                            name="jedinica_mere"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.jedinica_mere}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="pdv"
                            name="pdv"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.pdv}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">USPON Cene</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="B2B Cena"
                            name="b2bcena"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.b2bcena}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="Web Cena"
                            name="web_cena"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.web_cena}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <InputField
                            label="MP Cena"
                            name="mpcena"
                            error={errors}
                            errorsHandler={setErrors}
                            value={data.mpcena}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            className="mb-4"
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">Dodatna podešavanja</div>
                    <div className="card-body">
                      <div className="row">
                        <div className="row">
                          <div className="col-lg-4 col-sm-12">
                            <FormControl fullWidth className="mb-2">
                              <InputLabel id="demo-simple-select-label">
                                Status proizvoda
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={data.status ? data.status : 1}
                                label="Status proizvoda"
                                name="status"
                                onChange={handleInputChange}
                                size="small"
                              >
                                <MenuItem value="1">Aktivan</MenuItem>
                                <MenuItem value="0">Neaktivan</MenuItem>
                              </Select>
                            </FormControl>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.akcija === "1"}
                                    onChange={handleInputChange}
                                    name="akcija"
                                  />
                                }
                                label="Akcija"
                              />
                            </FormGroup>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.izdvojeno === "1"}
                                    onChange={handleInputChange}
                                    name="izdvojeno"
                                  />
                                }
                                label="Izdvojeno"
                              />
                            </FormGroup>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.mesecna_akcija === "1"}
                                    onChange={handleInputChange}
                                    name="mesecna_akcija"
                                  />
                                }
                                label="Mesečna akcija"
                              />
                            </FormGroup>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.popularno === "1"}
                                    onChange={handleInputChange}
                                    name="popularno"
                                  />
                                }
                                label="Popularno"
                              />
                            </FormGroup>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.ponovo_na_stanju === "1"}
                                    onChange={handleInputChange}
                                    name="ponovo_na_stanju"
                                  />
                                }
                                label="Najprodavanije"
                              />
                            </FormGroup>

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.preporucujemo === "1"}
                                    onChange={handleInputChange}
                                    name="preporucujemo"
                                  />
                                }
                                label="Preporučujemo"
                              />
                            </FormGroup>
                          </div>

                          <div className="col-lg-8 col-sm-12">
                            <h6 class="fs-15 mb-3">Ostale informacije</h6>
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <InputField
                                  label="energetska_klasa"
                                  name="energetska_klasa"
                                  error={errors}
                                  errorsHandler={setErrors}
                                  value={data.energetska_klasa}
                                  onChange={handleInputChange}
                                  fullWidth
                                  size="small"
                                  className="mb-4"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <InputField
                                  label="energetska_klasa_link"
                                  name="energetska_klasa_link"
                                  error={errors}
                                  errorsHandler={setErrors}
                                  value={data.energetska_klasa_link}
                                  onChange={handleInputChange}
                                  fullWidth
                                  size="small"
                                  className="mb-4"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <InputField
                                  label="energetska_klasa_pdf"
                                  name="energetska_klasa_pdf"
                                  error={errors}
                                  errorsHandler={setErrors}
                                  value={data.energetska_klasa_pdf}
                                  onChange={handleInputChange}
                                  fullWidth
                                  size="small"
                                  className="mb-4"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <InputField
                                  label="flag_akcija_cena"
                                  name="flag_akcija_cena"
                                  error={errors}
                                  errorsHandler={setErrors}
                                  value={data.flag_akcija_cena}
                                  onChange={handleInputChange}
                                  fullWidth
                                  size="small"
                                  className="mb-4"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <InputField
                                  label="datum_izmene"
                                  name="datum_izmene"
                                  error={errors}
                                  errorsHandler={setErrors}
                                  value={data.datum_izmene}
                                  onChange={handleInputChange}
                                  fullWidth
                                  size="small"
                                  className="mb-4"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6">
                                <InputField
                                  label="datum_unosa"
                                  name="datum_unosa"
                                  error={errors}
                                  errorsHandler={setErrors}
                                  value={data.datum_unosa}
                                  onChange={handleInputChange}
                                  fullWidth
                                  size="small"
                                  className="mb-4"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">Opis</div>
                    <div className="card-body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.opis.replace(/<br\s*\/?>/g, ""),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Product;
