import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { baseUrl, basicFormValidationHandler } from "../utils";
import {
  fetchCreateNewTraffic,
  fetchDeleteTraffic,
  fetchEditTraffic,
  fetchTrafficPagesList,
  fetchTrafficPagesListSearch,
  fetchTrafficPagesListSearch2,
} from "../actions/traffic";
import { useDispatch, useSelector } from "react-redux";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Add from "@mui/icons-material/Add";
import AggridTable from "../components/Admin/Reusable/AggridTable";
import Breadcrumb from "../components/Admin/Reusable/Breadcrumb";
import ButtonColumn from "../components/Admin/Reusable/ButtonColumn";
import CustomModal from "../components/Common/Modal/CustomModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Fragment } from "react";
import InputField from "../components/Admin/Reusable/InputField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { NumericFormat } from "react-number-format";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { statusRender } from "../utils/cellRenderTable";
import timezone from "dayjs/plugin/timezone"; // Uvozimo dodatak za rad sa vremenskim zonama
import { useEffect } from "react";
import { useModalToggle } from "../hooks/useModalToggle";
import utc from "dayjs/plugin/utc"; // Uvozimo dodatak za rad sa UTC vremenom

// Aktiviramo uvezene dodatke
dayjs.extend(utc);
dayjs.extend(timezone);

// Postavljamo željenu vremensku zonu
dayjs.tz.setDefault("Europe/Belgrade");

// Funkcija koja definiše kako će se formatirati uneti broj
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      prefix=""
    />
  );
});

// Funkcija koja definiše kako će se formatirati uneti broj
const NumberFormatCustom2 = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      prefix=""
      suffix=" RSD"
    />
  );
});

const TrafficList = () => {
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  const { dataPagesList, dataPagesDetail } = useSelector(
    (state) => state.trafficReducer
  );
  const loggedUserReducer = useSelector((state) => state.loggedUserReducer);

  const userData = loggedUserReducer?.loggedUser?.loggedUser;

  const [filteredRows, setFilteredRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [resetFilters, setResetFilters] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [preview, setPreview] = useState(false);

  const handleFilterChange = (filteredData) => {
    // Ovde možete postaviti filtrirane redove u stanje roditeljske komponente
    setFilteredRows(filteredData);
  };

  //   useEffect(() => {
  //     dispatch(fetchTrafficPagesList());
  //   }, [dispatch]);

  //akcija brisanja iz liste
  const handleSubmitModal = (pageId) => {
    dispatch(fetchDeleteTraffic(pageId));
  };

  const getTrafficeDetail = (dataValue) => {
    setPreview(false);
    dispatch(fetchTrafficPagesList(dataValue.id));
  };

  const getTrafficeDetailPreview = (dataValue) => {
    setPreview(true);
    dispatch(fetchTrafficPagesList(dataValue.id));
  };

  const initialState = {
    datum_od: dayjs(),
    datum_do: dayjs(),
    zaposleni: userData["role_id"] == 1 ? 0 : userData["user_id"],
  };

  const initialStateForm = {
    ime_putnika: "",
    broj_karata: 1,
    cena_karte: "",
    tsc: "",
    datum_izdavanja: dayjs(),
    kompanija: "Air Serbia",
    napomena: "",
    pnr: "",
    valuta: "rsd",
  };

  const options = [
    "Aegean Airlines",
    "Air Europa",
    "Air France",
    "Air Montenegro",
    "Air Serbia",
    "Alitalia",
    "American Airlines",
    "Austrian Airlines",
    "British Airways",
    "Delta Air Lines",
    "Fly Dubai",
    "Fly Emirates",
    "Etihad Airways",
    "KLM Royal Dutch Airlines",
    "LOT Polish Airlines",
    "Lufthansa",
    "Qatar Airways",
    "Singapore Airlines",
    "SWISS Air",
    "Turkish Airlines",
    "United Airlines",
  ];

  const [data, setData] = useState(initialState);
  const [dataForm, setDataForm] = useState(initialStateForm);
  const [showModal, toggleModal] = useModalToggle(false);
  const [showModalRemove, toggleModalRemove] = useModalToggle(false);

  const filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      // pretvaranje datuma u milisekundama
      var cellDate = new Date(cellValue).setHours(0, 0, 0, 0);

      if (cellDate == null) {
        return -1;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
    browserDatePicker: true, // omogućavanje date picker-a u filteru
    clearButton: true, // omogućavanje dugmeta za brisanje filtera
  };

  const filterParamsPrice = {
    valueFormatter: (params) => formatirajCenu(parseFloat(params.value)), // Formatiranje prikazane vrednosti u filteru
    valueParser: function (value) {
      const parsedValue = parseFloat(
        value.toString().replace(/\./g, "").replace(",", ".")
      );
      return parsedValue;
    },
  };

  const formatirajDatum = (datum) => {
    const dateObj = new Date(datum);

    const dan = dateObj.getDate().toString().padStart(2, "0");
    const mesec = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Meseci kreću od 0, pa se dodaje 1
    const godina = dateObj.getFullYear();

    return `${dan}.${mesec}.${godina} `;
  };

  const customCellRenderer = (params) => (
    <Fragment>
      <Tooltip title="Pregled karte" disableInteractive placement="top">
        <SearchIcon
          className="custom-btn-aggrid-customization"
          onClick={() => getTrafficeDetailPreview(params.data)}
        />
      </Tooltip>
      <Tooltip title="Izmena" disableInteractive placement="top">
        <EditNoteIcon
          className="custom-btn-aggrid-customization edit-button-aggrid-delete-button"
          onClick={() => getTrafficeDetail(params.data)}
        />
      </Tooltip>
      <Tooltip title="Brisanje" placement="top" disableInteractive>
        <DeleteIcon
          className="edit-button-aggrid-delete-button custom-btn-aggrid-customization"
          onClick={() => handleButtonRemoveClick(params.data)}
        />
      </Tooltip>
    </Fragment>
  );

  const modalCloseHandler = () => {
    toggleModal(false);
  };

  const handleButtonRemoveClick = (data) => {
    setSelectedRow(data);
    toggleModalRemove();
    setPreview(false);
  };

  const formatirajCenu = (cena) => {
    return cena.toLocaleString("sr-RS", { minimumFractionDigits: 2 });
  };

  const groupedData = filteredRows.reduce((acc, current) => {
    const fullName = `${current.ins_firstname} ${current.ins_lastname}`; // Spajanje imena i prezimena

    const existingEntry = acc.find((item) => item.ime_zaposlenog === fullName);

    if (existingEntry) {
      existingEntry.broj_karata += parseInt(current.broj_karata, 10);
      if (current.valuta == "rsd") {
        existingEntry.zbir_cena_karteRSD += parseFloat(current.cena_karte);
      } else if (current.valuta == "eur") {
        existingEntry.zbir_cena_karteEUR += parseFloat(current.cena_karte);
      }
      existingEntry.zbir_tsc += parseFloat(current.tsc);
    } else {
      const newEntry = {
        ime_zaposlenog: fullName,
        broj_karata: parseInt(current.broj_karata, 10),
        zbir_cena_karteRSD:
          current.valuta === "rsd" ? parseFloat(current.cena_karte) : 0,
        zbir_cena_karteEUR:
          current.valuta === "eur" ? parseFloat(current.cena_karte) : 0,
        zbir_tsc: parseFloat(current.tsc),
      };
      acc.push(newEntry);
    }
    return acc;
  }, []);

  //settings za tabelu
  const columnDefs = [
    // {
    //   headerName: "ID",
    //   field: "id",
    //   filter: "agTextColumnFilter",
    //   width: 100,
    // },
    {
      headerName: "Zaposleni",
      field: "fullName", // Polje koje će sadržavati spojeno ime i prezime
      filter: "agTextColumnFilter",
      width: 160,
      // pinned: "left",
      valueGetter: function (params) {
        // Spajanje imena i prezimena u jedan string
        return params.data.ins_firstname + " " + params.data.ins_lastname;
      },
    },
    {
      headerName: "Ime putnika",
      field: "ime_putnika",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "PNR",
      field: "pnr",
      width: 130,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Cena karte",
      field: "cena_karte",
      width: 140,
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatirajCenu(parseFloat(params.value)),
      filterParams: filterParamsPrice,
      aggFunc: "sum",
      comparator: function (valueA, valueB) {
        return parseFloat(valueA) - parseFloat(valueB);
      },
    },
    {
      headerName: "Valuta",
      field: "valuta",
      width: 130,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "TSC",
      field: "tsc",
      width: 130,
      valueFormatter: (params) => formatirajCenu(parseFloat(params.value)),
      filter: "agNumberColumnFilter",
      comparator: function (valueA, valueB) {
        return parseFloat(valueA) - parseFloat(valueB);
      },
    },
    {
      headerName: "Br. karata",
      field: "broj_karata",
      width: 120,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Kompanija",
      field: "kompanija",
      width: 160,
      filter: "agTextColumnFilter",
      tooltipValueGetter: (params) => params.data.kompanija,
    },
    {
      headerName: "Napomena",
      field: "napomena",
      filter: "agTextColumnFilter",
      tooltipValueGetter: (params) => params.data.napomena,
    },
    {
      headerName: "Dat. izd. karte",
      field: "datum_izdavanja",
      width: 180,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatirajDatum(params.value),
      filterParams: filterParams,
    },
    {
      headerName: "Datum kreiranja",
      field: "datum_kreiranja",
      filter: "agDateColumnFilter",
      hide: userData["role_id"] == 1 ? false : true,
    },
    {
      headerName: "",
      field: "custom",
      cellRenderer: customCellRenderer,
      pinned: window.innerWidth < 786 ? "" : "right",
      width: 120,
    },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleInputChangeForm = (event) => {
    const { name, value } = event.target;
    setDataForm((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const dataFormatted = {
      datum_od: dayjs(data.datum_od)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      datum_do: dayjs(data.datum_do)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      zaposleni: data.zaposleni,
    };

    dispatch(fetchTrafficPagesListSearch(dataFormatted));

    setResetFilters((prevResetFilters) => !prevResetFilters);
  };

  const handleDateChange = (date, field) => {
    setData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleDateChangeForm = (date, field) => {
    setDataForm((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const totalSum = filteredRows.reduce(
    (acc, current) => {
      if (current.valuta == "rsd") {
        acc.totalCenaKarteRSD += parseFloat(current.cena_karte);
      } else if (current.valuta == "eur") {
        acc.totalCenaKarteEUR += parseFloat(current.cena_karte);
      }

      acc.totalBrojKarata += parseInt(current.broj_karata, 10);
      acc.totalTsc += parseFloat(current.tsc);
      return acc;
    },
    {
      totalBrojKarata: 0,
      totalCenaKarteRSD: 0,
      totalCenaKarteEUR: 0,
      totalTsc: 0,
    }
  );

  useEffect(() => {
    const dataFormatted = {
      datum_od: dayjs(data.datum_od)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      datum_do: dayjs(data.datum_do)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      zaposleni: data.zaposleni,
    };
    if (data.zaposleni > 0)
      dispatch(fetchTrafficPagesListSearch2(dataFormatted));
  }, [dispatch]);

  useEffect(() => {
    setFilteredRows(dataPagesList);
  }, [dataPagesList]);

  const clientValidationHandler = () => {
    const { ime_putnika, pnr, cena_karte, tsc, kompanija } = dataForm;
    const errorsObject = basicFormValidationHandler({
      ime_putnika,
      pnr,
      cena_karte,
      tsc,
      kompanija,
    });
    setErrors(errorsObject);
    return !isEmpty(errorsObject);
  };

  //akcija brisanja iz liste
  const submitActionModal = () => {
    if (clientValidationHandler()) {
      return;
    }

    const dataFormatted = {
      datum_od: dayjs(data.datum_od)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      datum_do: dayjs(data.datum_do)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      zaposleni: data.zaposleni,
    };

    if (isEdit) {
      dataForm.id = dataPagesDetail.id;
      dispatch(fetchEditTraffic(dataForm, modalCloseHandler, dataFormatted));
    } else {
      dispatch(fetchCreateNewTraffic(dataForm, resetDataForm, dataFormatted)); // Čekamo da se akcija završi
    }
  };

  //akcija brisanja iz liste
  const submitActionModalRemoveItem = (id) => {
    const dataFormatted = {
      datum_od: dayjs(data.datum_od)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      datum_do: dayjs(data.datum_do)
        .tz("Europe/Belgrade", true)
        .format("YYYY-MM-DD"),
      zaposleni: data.zaposleni,
    };
    dispatch(fetchDeleteTraffic(id, closeModalFuncion, dataFormatted));
  };

  const resetDataForm = () => {
    setDataForm(initialStateForm);
  };

  const closeModalFuncion = () => {
    toggleModalRemove(false);
  };

  const toggleModalFunction = () => {
    setDataForm(initialStateForm);
    setIsEdit(false);
    setPreview(false);
    toggleModal();
  };

  useEffect(() => {
    if (Object.keys(dataPagesDetail).length > 0) {
      setIsEdit(true);
      setDataForm({
        ime_putnika: dataPagesDetail.ime_putnika,
        broj_karata: dataPagesDetail.broj_karata,
        cena_karte: dataPagesDetail.cena_karte,
        tsc: dataPagesDetail.tsc,
        datum_izdavanja: dayjs(),
        kompanija: dataPagesDetail.kompanija,
        napomena: dataPagesDetail.napomena,
        pnr: dataPagesDetail.pnr,
        valuta: dataPagesDetail.valuta,
      });
      toggleModal();
    }
  }, [dataPagesDetail]);

  return (
    <Fragment>
      <Breadcrumb title="Pregled prometa zarade" type="traffic-lista" />
      <div className="row">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="filtered-inputs-form">
                <div className="filtered-inputs-form-content">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        <div className="col-lg-2 col-md-3">
                          <DatePicker
                            label="Datum od"
                            name="datum_od"
                            value={data.datum_od}
                            onChange={(date) =>
                              handleDateChange(date, "datum_od")
                            }
                            fullWidth
                            slotProps={{ textField: { size: "small" } }}
                            inputSize="small"
                            format="DD.MM.YYYY"
                            className="w-100 mb-4"
                          />
                        </div>
                        <div className="col-lg-2">
                          <DatePicker
                            label="Datum do"
                            name="datum_do"
                            value={data.datum_do}
                            onChange={(date) =>
                              handleDateChange(date, "datum_do")
                            }
                            fullWidth
                            inputSize="small "
                            slotProps={{ textField: { size: "small" } }}
                            format="DD.MM.YYYY"
                            className="w-100 mb-4"
                          />
                        </div>
                        <div className="col-lg-3">
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Zaposleni
                            </InputLabel>
                            <Select
                              disabled={userData["role_id"] != 1 && true}
                              labelId="demo-select-small-label"
                              id="demo-simple-select"
                              value={data.zaposleni}
                              label="Zaposleni"
                              name="zaposleni"
                              onChange={handleInputChange}
                              className="w-100 mb-4"
                            >
                              <MenuItem value={0}>Svi</MenuItem>
                              <MenuItem value={19}>Marko Savić</MenuItem>
                              <MenuItem value={20}>Dragana Subotić</MenuItem>
                              <MenuItem value={21}>Violeta Paunović</MenuItem>
                              <MenuItem value={22}>Tamara Vujošević</MenuItem>
                              <MenuItem value={23}>Ivona Mitić</MenuItem>
                              <MenuItem value={24}>Tamara Atlagić</MenuItem>
                              <MenuItem value={25}>Milan Savić</MenuItem>
                              <MenuItem value={26}>Mladenko Radonjić</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-lg-2">
                          <Button
                            type="submit"
                            variant="contained"
                            color="info"
                            startIcon={<SearchIcon />}
                            className="w-100 mb-4"
                          >
                            Pretraga
                          </Button>
                        </div>
                        <div className="col-lg-3">
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<Add />}
                            className="w-100 mb-4"
                            onClick={() => toggleModalFunction()}
                          >
                            Dodaj novu kartu
                          </Button>
                        </div>
                      </div>
                    </form>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div
              className={`${
                userData["role_id"] == 1 ? "col-lg-12" : "col-lg-12"
              }`}
            >
              <AggridTable
                resetFilters={resetFilters}
                rowData={dataPagesList}
                columnDefs={columnDefs}
                onFilterChange={handleFilterChange}
              />
            </div>
            {!!userData && userData["role_id"] == 1 && (
              <div className="col-lg-12 list-user-items">
                <div className="row">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-5">
                        <div className="card">
                          <div className="card-header">
                            <div className="info-table-header">
                              Sumarni pregled ukupnog prometa
                            </div>
                          </div>
                          <div className="card-body">
                            <table className="table table-sm table-striped table-bordered">
                              <thead className="table-success">
                                <tr>
                                  <th>Broj karata</th>
                                  <th>Ukupna suma RSD</th>
                                  <th>Ukupna suma EUR</th>
                                  <th>Ukupna suma TSC</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{totalSum.totalBrojKarata}</td>
                                  <td>
                                    {formatirajCenu(totalSum.totalCenaKarteRSD)}
                                  </td>
                                  <td>
                                    {formatirajCenu(totalSum.totalCenaKarteEUR)}
                                  </td>
                                  <td>{formatirajCenu(totalSum.totalTsc)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7">
                        <div className="card">
                          <div className="card-header">
                            <div className="info-table-header">
                              Sumarni pregled ukupnog prometa po zaposlenom
                            </div>
                          </div>
                          <div className="card-body">
                            <table className="table table-sm table-striped table-bordered">
                              <thead className="table-success">
                                <tr>
                                  <th>Zaposleni</th>
                                  <th>Broj karata</th>
                                  <th>Ukupna suma RSD</th>
                                  <th>Ukupna suma EUR</th>
                                  <th>Ukupna suma TSC</th>
                                </tr>
                              </thead>
                              <tbody>
                                {groupedData && groupedData.length > 0 ? (
                                  groupedData
                                    .sort((a, b) => b.zbir_tsc - a.zbir_tsc) // Sortiranje prema zbir_tsc u opadajućem redosledu
                                    .map((item) => (
                                      <tr key={item.ime_zaposlenog}>
                                        <td>{item.ime_zaposlenog}</td>
                                        <td>{item.broj_karata}</td>
                                        <td>
                                          {formatirajCenu(
                                            item.zbir_cena_karteRSD
                                          )}
                                        </td>
                                        <td>
                                          {formatirajCenu(
                                            item.zbir_cena_karteEUR
                                          )}
                                        </td>
                                        <td>{formatirajCenu(item.zbir_tsc)}</td>
                                      </tr>
                                    ))
                                ) : (
                                  <tr>
                                    <td colSpan="5">Trenutno nema podataka</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModalRemove && (
        <CustomModal
          show={showModalRemove}
          size={"lg"}
          hideModal={() => toggleModalRemove()}
          modalTitle={`Potvrda brisanja karte`}
          actionSubmitModal={() => submitActionModalRemoveItem(selectedRow.id)}
        >
          Da li ste sigurni da želite da obrišete kartu <b>{selectedRow.pnr}</b>
          ?
        </CustomModal>
      )}
      {showModal && (
        <CustomModal
          show={showModal}
          footerContent={preview == true ? false : true}
          size={"lg"}
          hideModal={modalCloseHandler}
          modalTitle={
            preview
              ? `Pregled karte "` + dataForm.pnr + `"`
              : isEdit
              ? `Izmena karte`
              : `Dodavanje nove karte`
          }
          actionSubmitModal={() => submitActionModal()}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form encType="multipart/form-data" noValidate>
              <div className="row">
                <div className="col-12">
                  <Alert severity="info" className="mb-3">
                    Polja označena zvezdicom su obavezna pri dodavanju nove
                    karte.
                  </Alert>

                  <InputField
                    label="Ime i prezime putnika"
                    name="ime_putnika"
                    autoFocus
                    value={dataForm.ime_putnika}
                    error={errors}
                    onChange={handleInputChangeForm}
                    required
                    fullWidth
                    inputProps={{
                      readOnly: preview,
                    }}
                    size="small"
                    className="mb-4"
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <InputField
                        label="PNR"
                        name="pnr"
                        error={errors}
                        value={dataForm.pnr}
                        onChange={handleInputChangeForm}
                        required
                        fullWidth
                        inputProps={{
                          readOnly: preview,
                        }}
                        size="small"
                        className="mb-4"
                      />
                    </div>
                    <div className="col-lg-6">
                      <FormControl fullWidth className="mb-4">
                        <InputLabel id="demo-simple-select-label">
                          Broj karata
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          required
                          id="demo-simple-select"
                          value={dataForm.broj_karata}
                          label="Broj karata"
                          name="broj_karata"
                          inputProps={{
                            readOnly: preview,
                          }}
                          onChange={handleInputChangeForm}
                          size="small"
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="11">11</MenuItem>
                          <MenuItem value="12">12</MenuItem>
                          <MenuItem value="13">13</MenuItem>
                          <MenuItem value="14">14</MenuItem>
                          <MenuItem value="15">15</MenuItem>
                          <MenuItem value="16">16</MenuItem>
                          <MenuItem value="17">17</MenuItem>
                          <MenuItem value="18">18</MenuItem>
                          <MenuItem value="19">19</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                          <MenuItem value="21">21</MenuItem>
                          <MenuItem value="22">22</MenuItem>
                          <MenuItem value="23">23</MenuItem>
                          <MenuItem value="24">24</MenuItem>
                          <MenuItem value="25">25</MenuItem>
                          <MenuItem value="26">26</MenuItem>
                          <MenuItem value="27">27</MenuItem>
                          <MenuItem value="28">28</MenuItem>
                          <MenuItem value="29">29</MenuItem>
                          <MenuItem value="30">30</MenuItem>
                          <MenuItem value="31">31</MenuItem>
                          <MenuItem value="32">32</MenuItem>
                          <MenuItem value="33">33</MenuItem>
                          <MenuItem value="34">34</MenuItem>
                          <MenuItem value="35">35</MenuItem>
                          <MenuItem value="36">36</MenuItem>
                          <MenuItem value="37">37</MenuItem>
                          <MenuItem value="38">38</MenuItem>
                          <MenuItem value="39">39</MenuItem>
                          <MenuItem value="40">40</MenuItem>
                          <MenuItem value="41">41</MenuItem>
                          <MenuItem value="42">42</MenuItem>
                          <MenuItem value="43">43</MenuItem>
                          <MenuItem value="44">44</MenuItem>
                          <MenuItem value="45">45</MenuItem>
                          <MenuItem value="46">46</MenuItem>
                          <MenuItem value="47">47</MenuItem>
                          <MenuItem value="48">48</MenuItem>
                          <MenuItem value="49">49</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="51">51</MenuItem>
                          <MenuItem value="52">52</MenuItem>
                          <MenuItem value="53">53</MenuItem>
                          <MenuItem value="54">54</MenuItem>
                          <MenuItem value="55">55</MenuItem>
                          <MenuItem value="56">56</MenuItem>
                          <MenuItem value="57">57</MenuItem>
                          <MenuItem value="58">58</MenuItem>
                          <MenuItem value="59">59</MenuItem>
                          <MenuItem value="60">60</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-6">
                      <Autocomplete
                        id="free-solo-demo"
                        readOnly={preview}
                        freeSolo
                        className="w-100 mb-4"
                        defaultValue={dataForm.kompanija}
                        value={dataForm.kompanija}
                        onChange={(event, newValue) =>
                          handleInputChangeForm({
                            target: { name: "kompanija", value: newValue },
                          })
                        }
                        options={options}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="kompanija"
                            onChange={handleInputChangeForm}
                            label="Avio kompanija"
                            size="small"
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-6">
                      <DatePicker
                        required
                        label="Datum izdavanja karte"
                        name="datum_izdavanja"
                        readOnly={preview}
                        value={dataForm.datum_izdavanja}
                        onChange={(date) =>
                          handleDateChangeForm(date, "datum_izdavanja")
                        }
                        fullWidth
                        slotProps={{ textField: { size: "small" } }}
                        inputSize="small"
                        format="DD.MM.YYYY"
                        className="w-100 mb-4"
                      />
                    </div>

                    <div className="col-lg-3">
                      <FormControl fullWidth className="mb-4">
                        <InputLabel id="demo-simple-select-label">
                          Valuta
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          required
                          id="demo-simple-select"
                          value={dataForm.valuta}
                          label="Valuta"
                          name="valuta"
                          onChange={handleInputChangeForm}
                          size="small"
                          inputProps={{
                            readOnly: preview,
                          }}
                        >
                          <MenuItem value="rsd">RSD</MenuItem>
                          <MenuItem value="eur">EUR</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      <InputField
                        label="Cena karte"
                        value={dataForm.cena_karte}
                        name={"cena_karte"}
                        error={errors}
                        onChange={(e) => {
                          setDataForm((prevUser) => ({
                            ...prevUser,
                            cena_karte: e.target.value,
                          }));
                        }}
                        inputProps={{
                          inputComponent: NumberFormatCustom,
                          readOnly: preview,
                        }}
                        required
                        fullWidth
                        size="small"
                        className="mb-4"
                      />
                    </div>

                    <div className="col-lg-6">
                      <InputField
                        label="TSC"
                        name={"tsc"}
                        value={dataForm.tsc}
                        onChange={(e) => {
                          setDataForm((prevUser) => ({
                            ...prevUser,
                            tsc: e.target.value,
                          }));
                        }}
                        inputProps={{
                          inputComponent: NumberFormatCustom2,
                          readOnly: preview,
                        }}
                        required
                        error={errors}
                        fullWidth
                        size="small"
                        className="mb-4"
                      />
                    </div>
                  </div>
                  <InputField
                    label="Napomena"
                    name="napomena"
                    value={dataForm.napomena}
                    onChange={handleInputChangeForm}
                    fullWidth
                    inputProps={{
                      readOnly: preview,
                    }}
                    rows={5}
                    multiline
                    size="small"
                    className="mb-4"
                  />
                </div>
              </div>
            </form>
          </LocalizationProvider>
        </CustomModal>
      )}
    </Fragment>
  );
};

export default TrafficList;
