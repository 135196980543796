import api from "../api";

const apiService = {
  async get(url) {
    try {
      const response = await api.get(url, {
        headers: getSimpleHeaders(),
      });
      return response;
    } catch (error) {
      window.location.reload();
    }
  },
  async post(url, body) {
    try {
      const response = await api.post(url, body, {
        headers: getSimpleHeaders(),
      });
      return response;
    } catch (error) {
      window.location.reload();
    }
  },
  async delete(url) {
    try {
      const response = await api.delete(url, {
        headers: getSimpleHeaders(),
      });
      return response;
    } catch (error) {
      window.location.reload();
    }
  },

  async getFromFile(url) {
    try {
      const response = await api.get(url, {
        responseType: "blob",
        headers: getSimpleHeaders(),
      });
      return response;
    } catch (error) {
      window.location.reload();
    }
  },
};

function getSimpleHeaders() {
  const phpToken = localStorage.getItem("phpToken");
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `${phpToken}`,
  };
}

export default apiService;
